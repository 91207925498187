import React, { useState, useEffect, useRef } from "react";
import HorizontalLine from "../seperators/horizontal_line";
import { useRecoilValue } from "recoil";
import { screenStylesState } from "../../stores/screen_styles_state";
import { themeColorsState } from "../../stores/theme_colors_state";

const BuildingInfo = ({ mapService, resultData }) => {
  const [activeTab, setActiveTab] = useState("단지사진");
  const [activeType, setActiveType] = useState(null);
  const screenStyles = useRecoilValue(screenStylesState);
  const colorStyles = useRecoilValue(themeColorsState);

  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const [staticMapSrc, setStaticMapSrc] = useState("");

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // 스크롤 속도 조정
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUpOrLeave = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const initHouseType = (houseType) => {
    if (!houseType) {
      return "";
    }
    const typeString = houseType.match(/[a-zA-Z]+/g);
    const typeArea = parseInt(houseType.split(".")[0]);
    return typeString ? `${typeArea}${typeString}` : `${typeArea}`;
  };

  const typeData = resultData.details?.map((item) => ({
    type: initHouseType(item?.house_type),
    image: item.plan_path
      ? `https://found-assets-bucket.s3.ap-northeast-2.amazonaws.com/${item.plan_path}`
      : "",
  }));

  useEffect(() => {
    if (activeTab === "타입별정보") {
      setActiveType(typeData[0].type);
    }
  }, [activeTab]);

  useEffect(() => {
    setActiveTab("단지사진");
  }, [resultData]);

  useEffect(() => {
    const fetchMap = async () => {
      try {
        if (resultData.lat && resultData.lng) {
          const response = await mapService.getNaverStaticMap(
            resultData.lat,
            resultData.lng
          );
          if (!response) {
            throw new Error("Failed to fetch map");
          }

          setStaticMapSrc(response.mapImage); // 이미지 URL 생성
        }
      } catch (error) {
        console.error("Error fetching map:", error);
      }
    };

    fetchMap();
  }, [resultData]);

  const renderContent = () => {
    if (activeTab === "단지사진") {
      return (
        <div
          style={{
            textAlign: "center",
            marginTop: "15px",
            width: "100%",
            height: "250px",
            overflow: "hidden",
          }}
        >
          {resultData.view_path ? (
            <img
              src={`https://found-assets-bucket.s3.ap-northeast-2.amazonaws.com/${resultData.view_path}`}
              alt="단지 사진"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          ) : (
            <div
              style={{
                width: "100%",
                height: "250px",
                backgroundColor: "#f0f0f0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: colorStyles.gray,
              }}
            >
              준비중...
            </div>
          )}
        </div>
      );
    }

    if (activeTab === "지도") {
      return (
        <div
          style={{
            width: "100%",
            height: "250px",
            backgroundColor: "#f0f0f0",
            marginTop: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {staticMapSrc ? (
            <img
              src={staticMapSrc}
              alt="Naver Static Map"
              style={{ width: "100%", height: "100%", objectFit: "fill" }}
            />
          ) : (
            "지도 영역"
          )}
        </div>
      );
    }

    if (activeTab === "타입별정보") {
      return (
        <div>
          <div
            ref={containerRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUpOrLeave}
            onMouseLeave={handleMouseUpOrLeave}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            style={{
              display: "flex",
              gap: "10px",
              overflowX: "auto",
              paddingBottom: "10px",
              scrollbarWidth: "none", // Firefox
              msOverflowStyle: "none", // IE and Edge
              WebkitOverflowScrolling: "touch", // Smooth scrolling for iOS
            }}
            className="scrollableContainer"
          >
            {typeData.map((typeInfo, index) =>
              typeInfo?.type ? (
                <button
                  key={`type-button-${typeInfo.type || index}`}
                  onClick={() => setActiveType(typeInfo.type)}
                  style={{
                    display: "inline-block",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor:
                      activeType === typeInfo.type
                        ? colorStyles.main
                        : colorStyles.gray,
                    color: "white",
                    fontSize: "12px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    boxSizing: "border-box",
                    width: "40px",
                    height: "30px",
                    flexShrink: 0,
                  }}
                >
                  {typeInfo.type}
                </button>
              ) : (
                <div
                  key={`type-info-${index}`}
                  style={{
                    width: "100%",
                    paddingTop: "30px",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  정보없음
                </div>
              )
            )}
          </div>
          {activeType && (
            <div
              style={{
                textAlign: "center",
                marginTop: "15px",
                width: "100%",
                height: "250px",
                overflow: "hidden",
              }}
            >
              {typeData.find((t) => t.type === activeType)?.image ? (
                <img
                  src={typeData.find((t) => t.type === activeType)?.image}
                  alt={`${activeType} 이미지`}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "250px",
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: colorStyles.gray,
                  }}
                >
                  준비중...
                </div>
              )}
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "20px 0",
      }}
    >
      <div>
        <span style={{ fontSize: "16px", fontWeight: 600 }}>건물정보</span>
      </div>
      <div style={{ marginTop: "15px" }}>
        <div style={{ display: "flex", gap: "20px", marginBottom: "15px" }}>
          {["단지사진", "지도", "타입별정보"].map((tab) => (
            <div
              key={tab}
              onClick={() => {
                setActiveTab(tab);
                if (tab !== "타입별정보") {
                  setActiveType(null);
                }
              }}
              style={{
                cursor: "pointer",
                color: activeTab === tab ? "black" : colorStyles.gray,
                fontWeight: activeTab === tab ? 600 : 400,
                fontSize: "14px",
              }}
            >
              {tab}
            </div>
          ))}
        </div>

        <HorizontalLine size={1} color={colorStyles.dark_gray} />

        <div style={{ marginTop: "5px" }}>{renderContent()}</div>
      </div>

      {/* CSS 스타일 추가 */}
      <style>
        {`
          .scrollableContainer::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
    </section>
  );
};

export default BuildingInfo;
