export default class ContentService {
  constructor(http) {
    this.http = http;
  }

  //최신뉴스정보를 가져오는 함수
  async getNews(offset) {
    return await this.http.fetch(`/news?offset=${offset}`, {
      method: "GET",
    });
  }

  //입력한 단어와 관련된 검색어를 가져오는 함수
  async getSuggestedSearchTerms(search_term) {
    return await this.http.fetch(`/search/suggest?term=${search_term}`, {
      method: "GET",
    });
  }

  //최신순으로 아파트정보를 가져오는 함수
  async getRecentContentData(offset) {
    return await this.http.fetch(`/content/recent?offset=${offset}`, {
      method: "GET",
    });
  }

  //요청한 매수/매도 정보를 불러오는 함수
  async getReceptionData(id, type, phone) {
    return await this.http.fetch(
      `/content/reception?id=${id}&type=${type}&phone=${phone}`,
      {
        method: "GET",
      }
    );
  }

  async saveDocuments(formData) {
    return await this.http.fetch(`/content/documents/save`, {
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      body: formData,
    });
  }

  async getRankList() {
    return await this.http.fetch(`/content/rank`, {
      method: "GET",
    });
  }
}
