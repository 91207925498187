import React, { useState, useEffect } from "react";
import styles from "./search_rank.module.css";
import { useRecoilValue } from "recoil";
import { themeColorsState } from "../../stores/theme_colors_state";
import { useNavigate } from "react-router-dom";

const SearchRank = ({ contentService }) => {
  const colorStyles = useRecoilValue(themeColorsState);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigation = useNavigate();

  // Example search terms. Replace with dynamic data as needed.
  const [rankList, setRankList] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % rankList.length);
    }, 5000); // Change every 3 seconds

    return () => clearInterval(interval);
  }, [rankList.length]);

  useEffect(() => {
    initRankList();
  }, []);

  const initRankList = async () => {
    const response = await contentService.getRankList();
    const data = response?.result || [];
    setRankList(data);
  };

  const goDetailPage = (id) => {
    const encodeId = btoa(id);
    navigation(`/detail?id=${encodeId}`);
  };

  return (
    <section style={{ display: "flex", margin: "10px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          textWrap: "nowrap",
          fontSize: "12px",
          fontWeight: 600,
          color: colorStyles.gray,
        }}
      >
        실시간
      </div>
      {rankList && (
        <div className={styles.container}>
          <div
            key={currentIndex}
            className={styles.searchTerm}
            onClick={() => goDetailPage(rankList[currentIndex].post_id)}
          >
            <div
              style={{
                fontSize: "22px",
                color: colorStyles.main,
              }}
            >
              {currentIndex + 1}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "90%",
                fontSize: "16px",
                textWrap: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {rankList[currentIndex]?.new_house_nm}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default SearchRank;
