import { useRecoilValue } from "recoil";
import { screenStylesState } from "../stores/screen_styles_state";
import { themeColorsState } from "../stores/theme_colors_state";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import config from "../config";
import { AiOutlineFilePdf } from "react-icons/ai";

const ReceptionPage = ({ contentService }) => {
  const screenStyles = useRecoilValue(screenStylesState);
  const colorStyles = useRecoilValue(themeColorsState);

  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");
  const [tradeType, setTradeType] = useState("");
  const [resultData, setResultData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const navigation = useNavigate();

  useEffect(() => {
    const encodedId = searchParams.get("i");
    const tradeType = searchParams.get("t");
    setId(encodedId);
    setTradeType(tradeType);
  }, [searchParams]);

  useEffect(() => {
    if (id && isVerified) {
      getReception();
    }
    setIsLoading(false);
  }, [id, isVerified]);

  const getReception = async () => {
    try {
      const response = await contentService.getReceptionData(
        id,
        tradeType,
        phoneNumber
      );
      const data = response?.result || [];
      setResultData(data);
    } catch (error) {
      console.log(error.message);
      setIsVerified(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^\d+$/.test(value)) {
      setPhoneNumber(value);
    }
    if (value.length > 9) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const handlePhoneSubmit = (e) => {
    e.preventDefault();
    if (isActive) {
      setIsVerified(true);
    }
  };

  const handleFileClick = (path) => {
    const fullUrl = `https://${config.s3.bucket_name}.s3.ap-northeast-2.amazonaws.com/${path}`;
    window.open(fullUrl, "_blank");
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const verifyRender = () => {
    return (
      <section
        className="phone-verification"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
          padding: "0 15px",
        }}
      >
        <span style={{ fontSize: "20px", fontWeight: 600 }}>
          제출된 서류 및 메모 확인
        </span>
        <form style={{ display: "flex", justifyContent: "center" }}>
          <input
            type="tel"
            value={phoneNumber}
            onChange={handleChange}
            placeholder="등록한 연락처를 입력하세요"
            maxLength={11}
            style={{
              marginTop: "15px",
              padding: "0 10px",
              borderRadius: "8px",
              border: `1px solid ${colorStyles.gray}`,
              color: colorStyles.gray,
              width: "100%",
              height: "60px",
              fontSize: "16px",
            }}
          />
        </form>
        <button
          type="submit"
          style={{
            marginTop: "100px",
            padding: "10px 20px",
            borderRadius: "8px",
            backgroundColor: isActive
              ? colorStyles.main
              : colorStyles.light_gray,
            width: "100%",
            height: "50px",
            fontSize: "22px",
            color: "white",
            border: "none",
            cursor: isActive ? "pointer" : "",
          }}
          onClick={handlePhoneSubmit}
          disabled={isActive ? false : true}
        >
          확인
        </button>
      </section>
    );
  };

  return (
    <div
      className="container"
      style={{
        display: "flex",
        flexDirection: "column",
        width: screenStyles.width,
        height: "calc(var(--vh, 1vh) * 100)",
        backgroundColor: "white",
        position: "fixed",
        left: "50%",
        transform: "translate(-50%, 0)",
      }}
    >
      <section
        className="header"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: screenStyles.width,
          height: "47px",
          backgroundColor: "white",
          borderBottom: `1px ${colorStyles.dark_gray} solid`,
          overflow: "hidden",
        }}
      >
        <div
          className="logo"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "164px",
            height: "40px",
            cursor: "pointer",
          }}
          onClick={() => {}}
        >
          <img src="logo_row.png" alt="logo" width="80%" height="auto" />
        </div>
      </section>

      {!isVerified ? (
        verifyRender()
      ) : (
        <section
          className="main"
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            height: "100%",
            overflowY: "auto",
            scrollbarWidth: "none", // Firefox에서 스크롤 바 숨기기
            msOverflowStyle: "none", // IE 및 Edge에서 스크롤 바 숨기기
            "&::WebkitScrollbar": { display: "none" },
          }}
        >
          <section className="documents">
            <span
              style={{
                width: "100%",
                fontSize: "20px",
                fontWeight: 600,
                textAlign: "flex-start",
              }}
            >
              제출된 서류
            </span>

            {/* 파일 미리보기 */}
            <div
              className="file-grid"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "16px",
                width: "100%",
                maxWidth: "375px",
                marginTop: "15px",
              }}
            >
              {resultData?.[0]?.save_path?.length > 0 ? (
                resultData[0].save_path.map((path, index) => {
                  const isImage = /\.(jpg|jpeg|png|gif)$/i.test(path);
                  const isPDF = /\.pdf$/i.test(path);

                  return (
                    <div
                      key={index}
                      className="file-box"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        paddingTop: "100%",
                        position: "relative",
                        border: "1px solid lightgray",
                        borderRadius: "8px",
                        overflow: "hidden",
                        cursor: "pointer",
                      }}
                      onClick={() => handleFileClick(path)}
                    >
                      {isImage && (
                        <img
                          src={`https://${config.s3.bucket_name}.s3.ap-northeast-2.amazonaws.com/${path}`}
                          alt={`첨부파일 ${index + 1}`}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                      {isPDF && (
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "24px",
                            color: "gray",
                          }}
                        >
                          <AiOutlineFilePdf size={48} color="#FF0000" />
                        </div>
                      )}
                      <div
                        style={{
                          position: "absolute",
                          bottom: "8px",
                          fontSize: "14px",
                          backgroundColor: "rgba(255, 255, 255, 0.8)",
                          padding: "4px 8px",
                          borderRadius: "4px",
                        }}
                      >
                        첨부파일 {index + 1}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    gridColumn: "1 / -1",
                    textAlign: "center",
                    padding: "20px",
                  }}
                >
                  첨부한 파일이 없습니다.
                </div>
              )}
            </div>
          </section>
          {/* etc 내용 */}
          <div
            className="memo"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
              fontSize: "16px",
              lineHeight: "1.5",
              minHeight: "300px",
            }}
          >
            <span
              style={{
                width: "100%",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              메모
            </span>
            {resultData?.[0]?.etc ? (
              <div
                style={{
                  display: "flex",
                  minHeight: "200px",
                  width: "100%",
                  marginTop: "15px",
                }}
              >
                {resultData[0].etc}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "200px",
                }}
              >
                작성된 메모가 없습니다
              </div>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default ReceptionPage;
