import { useRecoilValue } from "recoil";
import { screenStylesState } from "../../stores/screen_styles_state";
import { themeColorsState } from "../../stores/theme_colors_state";
import { IoCloseOutline } from "react-icons/io5";
import CustomTooltip from "../../components/custom_tooltip/custom_tooltip";
import { useEffect, useState } from "react";
import { Slider } from "@mui/material";
import styles from "./buy_real_estate.module.css";
import CollectionAgreement from "../collection_agreement/collection_agreement";
import CustomSelect from "../custom_select/custom_select";

const BuyRealEstatePanel = ({ data = null, mapService, setShowBuyPanel }) => {
  const screenStyles = useRecoilValue(screenStylesState);
  const colorStyles = useRecoilValue(themeColorsState);

  const [priceInput, setPriceInput] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  const [phone, setPhone] = useState("");
  const [price, setPrice] = useState("");
  const [dong, setDong] = useState("");
  const [ho, setHo] = useState("");
  const [floor, setFloor] = useState("");

  const [inputValues, setInputValues] = useState([1, 24]);
  const timeRanges = {
    morning: [8, 11],
    lunch: [11, 13],
    afternoon: [13, 18],
    evening: [18, 20],
    lateEvening: [20, 23],
  };
  const [activeButton, setActiveButton] = useState("");

  const [content, setContent] = useState("");
  const [inputCount, setInputCount] = useState(0);
  const maxCount = 200;

  const [isInteresting, setIsInteresting] = useState(false);
  const [isAgreement, setIsAgreement] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showAgreement, setShowAgreement] = useState(false);

  const [isReservation, setIsReservation] = useState(true);

  const [originList, setOriginList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [error, setError] = useState("");

  const [newOrder, setNewOrder] = useState({
    presale_id: "",
    dong: "",
    ho: "",
    hope_price: 0,
    phone: "",
    possible_time: [1, 24],
    etc: "",
    floor: "",
    checked: false,
  });

  //data를 받아서 id가 있나없나 구분.
  //id가 있다는건 '매수'버튼을 눌러서 해당 item의 id값을 가져온 것임
  //id가 없으면 '매수 예약하기'버튼을 눌러 특정 분양권의 id값이 없음
  useEffect(() => {
    console.log(data);

    if (data.id) {
      setIsReservation(false);
      handleDataChange("area", data.area);
      handleDataChange("presale_id", data.presale_id);
      handleDataChange("presale_name", data.presale_name);
      handleDataChange("dong", data.dong);
      handleDataChange("ho", data.ho);
      handleDataChange("hope_price", data.hope_price);
      handleDataChange("house_type", data.house_type);
      setDong(data.dong);
      setHo(data.ho);
      setPrice(data.hope_price);
      setSelectedType(formatHouseData(data.house_type));
    } else {
      setIsReservation(true);
      handleDataChange("presale_id", data.presale_id);
      handleDataChange("area", data.area);
      handleDataChange("presale_name", data.presale_name);
    }

    setIsLoading(false);
  }, [data]);

  useEffect(() => {
    const fetchPresaleOptions = async () => {
      try {
        const response = await mapService.getTypeList(data.presale_id);
        // console.log(response.result);

        if (response.result.length > 0) {
          setOriginList(response.result);
          setTypeList(formatHouseData(response.result));
        } else {
          setSelectedType(data.house_type);
        }
      } catch (err) {
        setError("타입 정보를 불러오는데 실패했습니다.");
        console.log(err);
      }
    };

    fetchPresaleOptions();
  }, [data]);

  const formatHouseData = (data) => {
    if (typeof data === "object") {
      return data.map((item, index) => {
        let { house_type } = item;

        // house_type 처리: 문자가 있으면 숫자와 합치기
        const typeMatch = house_type.match(/^(\d+)\.(\d+)([A-Z])?$/);
        if (typeMatch) {
          const integerPart = parseInt(typeMatch[1], 10);
          const charPart = typeMatch[3] ? typeMatch[3] : "";
          // 문자가 있는 경우만 숫자+문자 형식으로 변환
          if (charPart) {
            house_type = `${integerPart}${charPart}`;
          }
        }
        // 최종 값 합치기
        return {
          id: index,
          name: `${house_type}`,
        };
      });
    } else {
      let house_type = data;
      const typeMatch = house_type.match(/^(\d+)\.(\d+)([A-Z])?$/);
      if (typeMatch) {
        const integerPart = parseInt(typeMatch[1], 10);
        const charPart = typeMatch[3] ? typeMatch[3] : "";
        // 문자가 있는 경우만 숫자+문자 형식으로 변환
        if (charPart) {
          house_type = `${integerPart}${charPart}`;
        }
      }
      // 최종 값 합치기
      return house_type;
    }
  };

  const handleDataChange = (key, value) => {
    setNewOrder((prevOrder) => ({
      ...prevOrder,
      [key]: value,
    }));
  };

  const handleInputChange = (setter) => (e) => {
    const name = e.target.name;
    const value = e.target.value;
    // console.log(name, e.target.checked);

    if (name === "etc") {
      setter(value);
      setInputCount(e.target.value.length);
      handleDataChange(name, value);
    } else if (name === "interest" || name === "agree") {
      const checked = e.target.checked;
      setter(checked);
      handleDataChange(name, checked);
    } else if (name === "type") {
      const _type = value.toUpperCase().replace(/[^A-Z0-9]/g, "");
      setter(_type);
      handleDataChange("house_type", _type);
    } else {
      if (value === "" || /^\d+$/.test(value)) {
        setter(value);
        handleDataChange(name, value);
      }
    }
  };

  const isWithinRange = (value, range) => {
    return value[0] >= range[0] && value[1] <= range[1];
  };

  const handleSliderChange = (event, newValue) => {
    setInputValues(newValue); // 스크롤이 바뀔 때 input 필드 업데이트
    let newActiveButton = "";
    for (const [key, range] of Object.entries(timeRanges)) {
      if (isWithinRange(newValue, range)) {
        newActiveButton = key;
        break;
      }
    }
    setActiveButton(newActiveButton);
    handleDataChange("possible_time", newValue);
  };

  const handleButtonChange = (newValue, buttonKey) => {
    setInputValues(newValue);
    setActiveButton(buttonKey); // 버튼 활성화
    handleDataChange("possible_time", newValue);
  };

  const handleSelectChange = (selected) => {
    // console.log(selected);
    const id = Number(selected.id);
    // console.log(originList[id]);
    setSelectedType(selected.name);
    handleDataChange("house_type", originList[id].house_type);
  };

  const validationOrder = () => {
    const { phone, dong, hope_price } = newOrder;
    // 필수 필드가 비어 있는지 확인
    if (isReservation) {
      if (!phone || !dong || !hope_price || !floor) {
        setErrorMessage(
          `필수 항목을 모두 입력해 주세요\n(연락처, 희망 가격, 동, 층, 타입)`
        );
        setShowModal(true); // 모달 띄우기
        return false;
      } else {
        if (!isAgreement) {
          setErrorMessage(`개인정보 수집 및 이용에 동의해주세요`);
          setShowModal(true);
          return false;
        } else {
          return true;
        }
      }
    } else {
      if (!phone || !dong || !hope_price) {
        setErrorMessage(
          `필수 항목을 모두 입력해 주세요\n(연락처, 희망 가격, 동)`
        );
        setShowModal(true); // 모달 띄우기
        return false;
      } else {
        if (!isAgreement) {
          setErrorMessage(`개인정보 수집 및 이용에 동의해주세요`);
          setShowModal(true);
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const closeAgreement = () => {
    setShowAgreement(false);
  };

  const handleSubmit = async () => {
    // setIsUploading(true);
    const isNext = validationOrder();
    // console.log("접수항목: ", newOrder);
    if (isNext) {
      newOrder.is_reservation = isReservation;

      const res = await mapService.saveBuyRealEstateOrder(newOrder);
      if (res.data === "ok") {
        alert(res.message);
        // setIsUploading(false);
        setShowBuyPanel(false);
      } else {
        // setIsUploading(false);
        alert(res.error);
      }
    }
    // setIsUploading(false);
  };

  const render = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
              marginBottom: "20px",
              gap: "8px",
            }}
          >
            <span style={{ fontSize: "20px", fontWeight: 600 }}>연락처</span>
            <span style={{ fontSize: "14px", color: colorStyles.gray }}>
              {"'-'를 제외한 숫자만 입력해주세요."}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "95%" }}
            >
              <input
                name="phone"
                style={{
                  height: "30px",
                  padding: "10px",
                  borderRadius: "5px",
                  flexGrow: 1,
                  border: `1px solid ${colorStyles.dark_gray}`,
                  imeMode: "disabled",
                  fontSize: "16px",
                }}
                type="text"
                placeholder="연락 가능한 연락처를 입력해주세요."
                value={phone || ""}
                onChange={handleInputChange(setPhone)}
                maxLength={"12"}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "40px",
            gap: "8px",
          }}
        >
          <span style={{ fontSize: "20px", fontWeight: 600 }}>
            희망 프리미엄 가격
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "95%" }}
            >
              <input
                name="hope_price"
                style={{
                  height: "30px",
                  padding: "10px",
                  borderRadius: "5px",
                  flexGrow: 1,
                  border: `1px solid ${colorStyles.dark_gray}`,
                  fontSize: "16px",
                }}
                type="text"
                placeholder="희망하는 프리미엄 가격을 입력해주세요."
                value={price || ""}
                onChange={handleInputChange(setPrice)}
              />
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  marginLeft: "5px",
                }}
              >
                만원
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "40px",
            gap: "8px",
          }}
        >
          <span style={{ fontSize: "20px", fontWeight: 600 }}>
            {!isReservation ? "희망 동 및 호수" : "희망 동 및 층수"}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "20px",
            }}
          >
            {!isReservation ? (
              <>
                <div>
                  <input
                    name="dong"
                    style={{
                      width: "215px",
                      height: "30px",
                      padding: "10px",
                      borderRadius: "5px",
                      border: `1px solid ${colorStyles.dark_gray}`,
                      fontSize: "16px",
                    }}
                    type="text"
                    placeholder="희망하는 동을 입력해주세요"
                    value={dong || ""}
                    disabled
                  />
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      marginLeft: "5px",
                    }}
                  >
                    동
                  </span>
                </div>
                <div>
                  <input
                    name="ho"
                    style={{
                      width: "215px",
                      height: "30px",
                      padding: "10px",
                      borderRadius: "5px",
                      border: `1px solid ${colorStyles.dark_gray}`,
                      fontSize: "16px",
                    }}
                    type="text"
                    placeholder="호수를 입력해주세요"
                    value={ho || ""}
                    disabled
                  />
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      marginLeft: "5px",
                    }}
                  >
                    호
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ width: "70%" }}>
                    {!isLoading && (
                      <div style={{ display: "flex" }}>
                        <input
                          style={{
                            width: "100%",
                            height: "30px",
                            padding: "10px",
                            borderRadius: "5px",
                            border: `1px solid ${colorStyles.dark_gray}`,
                            fontSize: "16px",
                          }}
                          type="text"
                          name="type"
                          placeholder="타입 (84A, 102B 등)"
                          value={selectedType || ""}
                          onChange={handleInputChange(setSelectedType)}
                          disabled={true}
                        />
                      </div>
                    )}
                  </div>
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      marginLeft: "5px",
                    }}
                  >
                    타입
                  </span>
                </div>
              </>
            ) : (
              <>
                <div>
                  <input
                    name="dong"
                    style={{
                      width: "215px",
                      height: "30px",
                      padding: "10px",
                      borderRadius: "5px",
                      border: `1px solid ${colorStyles.dark_gray}`,
                      fontSize: "16px",
                    }}
                    type="text"
                    placeholder="희망하는 동을 입력해주세요"
                    value={dong || ""}
                    onChange={handleInputChange(setDong)}
                  />
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      marginLeft: "5px",
                    }}
                  >
                    동
                  </span>
                </div>
                <div>
                  <input
                    name="floor"
                    style={{
                      width: "215px",
                      height: "30px",
                      padding: "10px",
                      borderRadius: "5px",
                      border: `1px solid ${colorStyles.dark_gray}`,
                      fontSize: "16px",
                    }}
                    type="text"
                    placeholder="희망하는 층을 입력해주세요"
                    value={floor || ""}
                    maxLength={3}
                    onChange={handleInputChange(setFloor)}
                  />
                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      marginLeft: "5px",
                    }}
                  >
                    층
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {!isLoading && typeList.length > 0 ? (
                    <div style={{ display: "flex", width: "237px" }}>
                      <CustomSelect
                        placeholder="타입 선택"
                        options={typeList}
                        onChange={handleSelectChange}
                        inputValue={selectedType || ""}
                      />
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <input
                        style={{
                          width: "215px",
                          height: "30px",
                          padding: "10px",
                          borderRadius: "5px",
                          border: `1px solid ${colorStyles.dark_gray}`,
                          fontSize: "16px",
                        }}
                        type="text"
                        name="type"
                        placeholder="타입 (84A, 102B 등)"
                        value={selectedType || ""}
                        onChange={handleInputChange(setSelectedType)}
                      />
                    </div>
                  )}

                  <span
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      marginLeft: "5px",
                    }}
                  >
                    타입
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "40px",
            gap: "8px",
          }}
        >
          <span style={{ fontSize: "20px", fontWeight: 600 }}>
            연락 가능 시간
          </span>
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
              color: colorStyles.main,
            }}
          >
            {`${inputValues[0]}시 ~ ${inputValues[1]}시`}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Slider
                  value={inputValues || ""}
                  onChange={handleSliderChange}
                  valueLabelDisplay="auto"
                  sx={{
                    width: "90%",
                    color: colorStyles.main,
                    margin: "30px 0px",
                    "& .MuiSlider-thumb": { color: "lightgray" },
                    "& .MuiSlider-markLabel": { color: "#49454f" },
                    "& .MuiSlider-track": { height: "1px" },
                  }}
                  min={1}
                  max={24}
                  step={1}
                  marks={[
                    { value: 1, label: "1시" },
                    { value: 12, label: "12시" },
                    { value: 24, label: "24시" },
                  ]}
                />
                <section
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    width: "100%",
                    height: "max-content",
                  }}
                >
                  {Object.entries(timeRanges).map(([key, range]) => (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        minWidth: "50px",
                        border: `1px solid ${colorStyles.gray}`,
                        borderRadius: "10px",
                        padding: "10px 20px",
                        color: "#49454F",
                        fontSize: "14px",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                        justifyContent: "center",
                        borderColor:
                          activeButton === key ? colorStyles.main : "",
                        borderWidth: activeButton === key ? "2px" : "1px",
                        color:
                          activeButton === key
                            ? colorStyles.main
                            : colorStyles.gray,
                        fontWeight: activeButton === key ? 600 : "",
                      }}
                      onClick={() => handleButtonChange(range, key)}
                    >
                      {key === "morning"
                        ? "오전"
                        : key === "lunch"
                        ? "점심시간"
                        : key === "afternoon"
                        ? "오후"
                        : key === "evening"
                        ? "저녁시간"
                        : "저녁시간 이후"}
                    </div>
                  ))}
                </section>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40px",
              gap: "8px",
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              메모
            </span>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <textarea
                name="etc"
                style={{
                  width: "310px",
                  height: "160px",
                  borderRadius: "5px",
                  padding: "10px",
                  flexGrow: 1,
                  resize: "none",
                  borderColor: colorStyles.dark_gray,
                  color: colorStyles.gray,
                  fontSize: "14px",
                  fontFamily: "Pretendard",
                }}
                value={content || ""}
                placeholder="통화 상담시 참고할만한 사항을 적어주세요."
                onChange={handleInputChange(setContent)}
                maxLength={maxCount}
              />
              <span
                style={{ display: "flex", alignSelf: "end", fontSize: "12px" }}
              >
                <span>{inputCount}</span>/<span>{maxCount}</span>
              </span>
            </div>
          </div>
        </div>
        <div
          className="checkBox"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "8px",
          }}
        >
          <label>
            <input
              name="interest"
              type="checkbox"
              checked={isInteresting}
              onChange={handleInputChange(setIsInteresting)}
            />
            비슷한 매물에도 관심 있어요
          </label>
          <div>
            <label>
              <input
                name="agree"
                type="checkbox"
                checked={isAgreement}
                onChange={handleInputChange(setIsAgreement)}
              />
              개인정보 수집 및 이용에 동의합니다
            </label>
            <span
              style={{
                fontSize: "10px",
                marginLeft: "5px",
                color: colorStyles.main,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setShowAgreement(true)}
            >
              자세히보기
            </span>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          width: screenStyles.width,
          height: "calc(var(--vh, 1vh) * 100)",
          backgroundColor: "white",
          position: "fixed",
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
      >
        <section
          className="header"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: screenStyles.width,
            height: "47px",
            backgroundColor: "white",
            borderBottom: `1px ${colorStyles.dark_gray} solid`,
          }}
        >
          <div
            className="title"
            style={{
              display: "flex",
              height: "48px",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "20px",
              fontWeight: 700,
              marginRight: "2px",
            }}
          >
            매수하기
          </div>
          <div
            style={{
              display: "flex",
              width: "10px",
              height: "70%",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <CustomTooltip
              position={"bottom"}
              size={12}
              text={
                "매물 확인 및 매수의사 확인을 위해 공인중개사와 통화 후 접수가 완료됩니다."
              }
            />
          </div>
          <div
            style={{
              position: "absolute",
              top: "5px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={() => setShowBuyPanel(false)}
          >
            <IoCloseOutline
              style={{
                width: "37px",
                height: "37px",
                strokeWidth: 1,
              }}
            />
          </div>
        </section>

        <section
          className="main"
          style={{
            margin: "20px",
            paddingBottom: "100px",
            flexGrow: 1,
            overflowY: "scroll", // 세로 스크롤만 활성화
            scrollbarWidth: "none", // Firefox에서 스크롤 바 숨기기
            msOverflowStyle: "none", // IE 및 Edge에서 스크롤 바 숨기기
            "&::WebkitScrollbar": { display: "none" },
          }}
        >
          {!isLoading && render()}
          <section
            className="buttonBox"
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: "white",
              position: "fixed",
              bottom: 0,
              right: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "50px",
                padding: "20px",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <button
                onClick={handleSubmit}
                style={{
                  backgroundColor: colorStyles.main,
                  color: "white",
                  border: "none",
                  width: "100%",
                  height: "90%",
                  borderRadius: "5px",
                  fontWeight: 500,
                  fontSize: "20px",
                  flex: 7,
                  cursor: "pointer",
                }}
              >
                매수접수
              </button>
            </div>
          </section>
        </section>

        {isUploading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: "50%",
              transform: "translate(-50%, 0)",
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <div
              className="spinner"
              style={{
                border: "6px solid rgba(255, 255, 255, 0.3)",
                borderTop: "6px solid white",
                borderRadius: "50%",
                width: "50px",
                height: "50px",
                animation: "spin 1s linear infinite",
              }}
            ></div>
          </div>
        )}
        {showModal && (
          <>
            <div
              style={{
                position: "fixed",
                top: 0,
                left: "50%",
                transform: "translate(-50%, 0)",
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: 999, // 모달 아래 배경 레이어
              }}
            />
            <div className={styles.modal}>
              <div className={styles.modal_content}>
                <p style={{ whiteSpace: "pre-line" }}>{errorMessage}</p>
                <button
                  style={{
                    width: "60px",
                    height: "40px",
                    backgroundColor: colorStyles.main,
                    color: "white",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={closeModal}
                >
                  확인
                </button>
              </div>
            </div>
          </>
        )}
        {showAgreement && <CollectionAgreement onClose={closeAgreement} />}
      </div>
    </>
  );
};

export default BuyRealEstatePanel;
