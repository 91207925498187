import { useEffect } from "react";
import ScreenSizeObserver from "./components/screen_size_observer/screen_size_observer";
import { Route, Routes } from "react-router-dom";
import MainPage from "./pages/main";
import SearchResultPage from "./pages/search_result";
import DetailPage from "./pages/detail";
import ReceptionPage from "./pages/reception";

function App({ mapService, contentService }) {
  const setScreenSize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    setScreenSize();
  }, [window.innerHeight]);

  useEffect(() => {
    // 페이지 전체에 대해 오른쪽 클릭 방지
    const handleContextMenu = (e) => {
      e.preventDefault();
      return false;
    };

    document.addEventListener("contextmenu", handleContextMenu);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <>
      <ScreenSizeObserver />
      <Routes>
        <Route
          path="/"
          element={<MainPage contentService={contentService} />}
        />
        <Route
          path="/search"
          element={
            <SearchResultPage
              mapService={mapService}
              contentService={contentService}
            />
          }
        />
        <Route
          path="/detail"
          element={
            <DetailPage
              mapService={mapService}
              contentService={contentService}
            />
          }
        />
        <Route
          path="/rcpt"
          element={<ReceptionPage contentService={contentService} />}
        />
      </Routes>
    </>
  );
}

export default App;
