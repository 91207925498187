import { useRecoilValue } from "recoil";
import { screenStylesState } from "../stores/screen_styles_state";
import { themeColorsState } from "../stores/theme_colors_state";
import SearchBar from "../components/search_bar/search_bar";
import { useNavigate } from "react-router-dom";
import CategoryButtonBar from "../components/category_button_bar/category_button_bar";
import SearchRank from "../components/search_rank/search_rank";
import CustomContentMain from "../components/custom_content_main/custom_content_main";
import { useEffect, useRef, useState } from "react";
import HorizontalLine from "../components/seperators/horizontal_line";
import { PiCubeThin } from "react-icons/pi";
import { FiUser } from "react-icons/fi";
import ScrollToTopButton from "../components/scroll_to_top_button/scroll_to_top_button";

const MainPage = ({ contentService }) => {
  const screenStyles = useRecoilValue(screenStylesState);
  const colorStyles = useRecoilValue(themeColorsState);

  const navigation = useNavigate();
  const onSearch = (inputText) => {
    navigation(`/search?term=${inputText}`);
  };

  const mainRef = useRef();

  const [initOptions, setInitOptions] = useState({
    지역: [],
    가격: [0, 5000000000],
    가격타입: "분양가",
    건물종류: [],
    면적: [0, 50],
    세대수: [0, 5000],
    주택수: null,
    분양상태: null,
  });

  const [contentList, setContentList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [hasMoreNews, setHasMoreNews] = useState(true);
  const [filterValue, setFilterValue] = useState(initOptions);
  const [showCategoryOptions, setShowCategoryOptions] = useState(false);
  const [contentOffset, setContentOffset] = useState(0);
  const [hasMoreContent, setHasMoreContent] = useState(true);
  const [newsOffset, setNewsOffset] = useState(0);
  const LIMIT_CNT = 3; //가져오는 개수

  // 컴포넌트가 마운트될 때 초기 데이터를 가져오는 useEffect
  useEffect(() => {
    initContentList();
    initNews(); // 처음에 3개의 뉴스를 가져옵니다.
  }, []);

  useEffect(() => {
    contentOffset !== 0 && initContentList();
  }, [contentOffset]);

  useEffect(() => {
    newsOffset !== 0 && initNews();
  }, [newsOffset]);

  const initContentList = async () => {
    const response = await contentService.getRecentContentData(contentOffset);
    const data = response?.result || [];

    if (data.length > 0) {
      setContentList((prevData) => [...prevData, ...data]);
      setHasMoreContent(data.length === LIMIT_CNT);
    } else {
      setHasMoreContent(false);
    }
  };

  const initNews = async () => {
    const response = await contentService.getNews(newsOffset);
    const data = response.result || [];
    if (data.length > 0) {
      setNewsList((prevData) => [...prevData, ...data]);
      setHasMoreNews(data.length === LIMIT_CNT);
    } else {
      setHasMoreNews(data.length === LIMIT_CNT);
    }
  };

  const fetchMoreContent = () => {
    setContentOffset((prev) => prev + LIMIT_CNT);
  };

  const fetchMoreNews = () => {
    setNewsOffset((prev) => prev + LIMIT_CNT);
  };

  const handleContentClick = (id) => {
    if (id) {
      const encodeId = btoa(id);
      navigation(`/detail?id=${encodeId}`);
    }
  };

  const handleNewsClick = (link) => {
    if (link) {
      window.open(link, "_blank"); // 링크를 새 창에서 엽니다.
    }
  };

  const onFilterSearch = (options) => {
    const optionsString = encodeURIComponent(JSON.stringify(options));
    navigation(`/search?filter=${optionsString}`);
  };

  return (
    <div
      className="container"
      style={{
        display: "flex",
        flexDirection: "column",
        width: screenStyles.width,
        height: "calc(var(--vh, 1vh) * 100)",
        backgroundColor: "white",
        position: "fixed",
        left: "50%",
        transform: "translate(-50%, 0)",
      }}
    >
      <section
        className="header"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: screenStyles.width,
          height: "47px",
          backgroundColor: "white",
          borderBottom: `1px ${colorStyles.dark_gray} solid`,
        }}
      >
        <div
          className="logo"
          style={{
            display: "flex",
            alignItems: "center",
            width: "15%",
            height: "100%",
          }}
        >
          <img src="logo.svg" alt="logo" width="100%" height="100%" />
        </div>
        {/* <FiUser
          style={{
            width: "15%",
            height: "80%",
            paddingLeft: "10px",
            strokeWidth: 1,
          }}
        /> */}
      </section>
      <section
        ref={mainRef}
        className="main"
        style={{
          touchAction: "pan-y",
          overflowY: "scroll", // 세로 스크롤만 활성화
          scrollbarWidth: "none", // Firefox에서 스크롤 바 숨기기
          msOverflowStyle: "none", // IE 및 Edge에서 스크롤 바 숨기기
          "&::WebkitScrollbar": { display: "none" },
        }}
      >
        <SearchRank contentService={contentService} />
        <SearchBar onSearch={onSearch} contentService={contentService} />
        <CategoryButtonBar
          categories={filterValue}
          onFilterSearch={onFilterSearch}
          setShowCategoryOptions={setShowCategoryOptions}
        />
        <HorizontalLine size={1} color={"#D9D9D9"} />
        <div
          className="contentWrapper"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="container">
            <section
              className="contentTitle"
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                marginTop: "8px",
                marginBottom: "20px",
                padding: "0 20px 0 10px",
              }}
            >
              {"청약 공고"}
            </section>
            <section
              className="contentBody"
              style={{
                paddingLeft: "10px",
                paddingRight: "20px",
              }}
            >
              {contentList.length > 0 ? (
                contentList.map((item, index) => (
                  <section
                    key={index}
                    className="content"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      className="textSection"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                        flex: 3,
                        overflow: "hidden",
                        cursor: "pointer",
                      }}
                      onClick={() => handleContentClick(item.id)}
                    >
                      <div className="type" style={{ fontSize: "14px" }}>
                        {item.house_secd_nm === "APT"
                          ? "아파트"
                          : item.house_secd_nm}
                      </div>
                      <div
                        className="title"
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {item.new_house_nm || item.house_nm}
                      </div>
                      <div
                        className="subTitle"
                        style={{
                          fontSize: "14px",
                          color: colorStyles.gray,
                        }}
                      >
                        {item.subscrpt_area_code_nm}
                      </div>
                    </div>
                  </section>
                ))
              ) : (
                <div>Loading...</div>
              )}
            </section>
            <section className="contentBottom" style={{ display: "flex" }}>
              {hasMoreContent ? (
                <button
                  className="moreButton"
                  style={{
                    width: "100%",
                    height: "43px",
                    fontSize: "14px",
                    color: "#c9c9c9",
                    background: "none",
                    border: "none",
                    borderTop: "1px solid #efefef",
                    borderBottom: "1px solid #efefef",
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={fetchMoreContent}
                >
                  더 보기
                </button>
              ) : (
                <div
                  style={{
                    width: "100%",
                    padding: "10px",
                    color: "gray",
                    textAlign: "center",
                  }}
                >
                  더 이상 불러올 공고가 없습니다.
                </div>
              )}
            </section>
          </div>
        </div>
        <HorizontalLine size={11} color={"#D9D9D9"} />
        <div className="container">
          <section
            className="contentTitle"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginTop: "8px",
              marginBottom: "20px",
              padding: "0 20px 0 10px",
            }}
          >
            {"뉴스"}
          </section>
          <section
            className="contentBody"
            style={{
              paddingLeft: "10px",
              paddingRight: "20px",
            }}
          >
            {newsList.length > 0 ? (
              newsList.map((item, index) => (
                <section
                  key={index}
                  className="content"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    className="textSection"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px",
                      flex: 3,
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                    onClick={() => handleNewsClick(item.link)}
                  >
                    <div className="type" style={{ fontSize: "14px" }}>
                      {item.type}
                    </div>
                    <div
                      className="title"
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.title}
                    </div>
                    <div
                      className="subTitle"
                      style={{
                        fontSize: "14px",
                        color: colorStyles.gray,
                      }}
                    >
                      {item.sub_title}
                    </div>
                  </div>
                </section>
              ))
            ) : (
              <div>Loading...</div>
            )}
          </section>
          <section className="contentBottom" style={{ display: "flex" }}>
            {hasMoreContent ? (
              <button
                className="moreButton"
                style={{
                  width: "100%",
                  height: "43px",
                  fontSize: "14px",
                  color: "#c9c9c9",
                  background: "none",
                  border: "none",
                  borderTop: "1px solid #efefef",
                  borderBottom: "1px solid #efefef",
                  padding: 0,
                  cursor: "pointer",
                }}
                onClick={fetchMoreNews}
              >
                더 보기
              </button>
            ) : (
              <div
                style={{
                  width: "100%",
                  padding: "10px",
                  color: "gray",
                  textAlign: "center",
                }}
              >
                더 이상 불러올 뉴스가 없습니다.
              </div>
            )}
          </section>
        </div>
        <HorizontalLine size={11} color={"#D9D9D9"} />
        <ScrollToTopButton target={mainRef} />
        <section
          className="footer"
          style={{
            display: "flex",
            flexDirection: "column",
            color: colorStyles.gray,
            padding: "16px 10px 16px 10px",
            backgroundColor: "white",
            borderTop: `1px ${colorStyles.dark_gray} solid`,
            borderBottom: `1px ${colorStyles.dark_gray} solid`,
            gap: "4px",
          }}
        >
          <span style={{ fontWeight: 700 }}>파운드</span>
          <div style={{ display: "flex", gap: "8px" }}>
            <span style={{ fontWeight: 700 }}>대표</span>
            <span>허란</span>
          </div>
          <div style={{ display: "flex", gap: "8px" }}>
            <span style={{ fontWeight: 700 }}>사업자등록번호</span>
            <span>625-11-02593</span>
          </div>
        </section>
      </section>
    </div>
  );
};

export default MainPage;
