import React, { useState, useRef, useEffect, useMemo } from "react";
import styles from "./around_info.module.css"; // CSS 모듈
import HorizontalLine from "../seperators/horizontal_line";
import { calculateUnitPrice } from "../../utils/calculate";
import SellRealEstateButton from "../sell_real_estate_button/sell_real_estate_button";
import CustomCarousel from "../custom_carousel/custom_carousel";
import CustomCarouselSmall from "../custom_carousel_small/custom_carousel_small";

const AroundInfo = ({ unitState, data, handleAroundClick }) => {
  const [isDragging, setIsDragging] = useState(false); // 드래그 상태
  const startPos = useRef(0); // 드래그 시작 시 마우스 위치 저장

  const [visibleItems, setVisibleItems] = useState(5); // 처음에는 5개의 아이템만 표시

  // 캐러셀 관련 상태와 ref
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isCarouselDragging, setIsCarouselDragging] = useState(false);
  const carouselRef = useRef(null);
  const carouselStartX = useRef(null);

  const listRef = useRef();
  const [currentData, setCurrentData] = useState();

  useEffect(() => {
    setCurrentData(data);
  }, [data]);

  // useEffect(() => {
  //   console.log(currentData);
  // }, [currentData]);

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      // 스크롤이 끝까지 내려왔을 때, 다음 5개 아이템 추가
      if (
        scrollTop + clientHeight >= scrollHeight - 10 &&
        visibleItems < data.length
      ) {
        loadMoreItems();
      }
    }
  };

  // 더 많은 아이템을 로드
  const loadMoreItems = () => {
    const newVisibleItems = visibleItems + 5;
    setCurrentData(data.slice(0, newVisibleItems)); // 5개씩 추가
    setVisibleItems(newVisibleItems);
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [visibleItems]);

  // 캐러셀용 드래그 시작 핸들러
  const handleCarouselDragStart = (e) => {
    carouselStartX.current = e.pageX - carouselRef.current.offsetLeft;
    setIsCarouselDragging(true);
  };

  // 캐러셀용 드래그 이동 핸들러
  const handleCarouselDragMove = (e) => {
    if (!isCarouselDragging) return;
    e.preventDefault();
    const x = e.pageX - carouselRef.current.offsetLeft;
    const walk = (x - carouselStartX.current) * 3; // * 3 for faster sliding
    if (walk < -100) {
      handleNextSlide();
      setIsCarouselDragging(false);
    } else if (walk > 100) {
      handlePrevSlide();
      setIsCarouselDragging(false);
    }
  };

  // 캐러셀용 드래그 종료 핸들러
  const handleCarouselDragEnd = () => {
    setIsCarouselDragging(false);
  };

  const handleNextSlide = () => {
    setCurrentSlide(
      (prev) => (prev + 1) % Math.ceil(currentData.presale_info.length / 5)
    );
  };

  const handlePrevSlide = () => {
    setCurrentSlide(
      (prev) =>
        (prev - 1 + Math.ceil(currentData.presale_info.length / 5)) %
        Math.ceil(currentData.presale_info.length / 5)
    );
  };

  const renderContent = () => {
    const getContractState = (obj) => {
      const today = new Date();
      const beginDate = new Date(obj.rcept_bgnde);
      const endDate = new Date(obj.rcept_endde);

      if (today < beginDate) {
        return (
          <span
            style={{
              fontSize: "12px",
              padding: "2px",
              fontWeight: 700,
              color: "white",
              border: "1px solid #ffcc00",
              backgroundColor: "#ffcc00",
              wordBreak: "keep-all",
            }}
          >
            {"분양전"}
          </span>
        );
      } else if (today > endDate) {
        return (
          <span
            style={{
              fontSize: "12px",
              padding: "2px",
              fontWeight: 700,
              color: "white",
              border: "1px solid #34C759",
              backgroundColor: "#34C759",
              wordBreak: "keep-all",
            }}
          >
            {"분양완료"}
          </span>
        );
      } else {
        return (
          <span
            style={{
              fontSize: "12px",
              padding: "2px",
              fontWeight: 700,
              color: "white",
              border: "1px solid #5856D6",
              backgroundColor: "#5856D6",
              wordBreak: "keep-all",
            }}
          >
            {"분양중"}
          </span>
        );
      }
    };

    const getUnitPrice = (obj) => {
      // console.log(obj);

      const perPriceObj = calculateUnitPrice(
        Number(obj.house_supply_area),
        Number(obj.max_supply_price),
        "meter"
      );
      const perSquarePrice = perPriceObj.pricePerSquareMeter;
      const perPyeongPrice = perPriceObj.pricePerPyeong;
      const resString =
        unitState === "pyeong"
          ? `${perPyeongPrice.toLocaleString()}만원/평`
          : `${perSquarePrice.toLocaleString()}만원/m²`;
      return `분양가 ${resString}`;
    };

    const getSupplyType = (obj) => {
      const supplyType = obj.house_secd_nm;
      if (supplyType === "APT") {
        return "아파트";
      } else {
        return supplyType;
      }
    };

    if (currentData) {
      return (
        <>
          <ul
            ref={listRef}
            className={styles.itemList}
            style={{
              overflowY: "auto",
              overflowY: "scroll", // 세로 스크롤만 활성화
              scrollbarWidth: "none", // Firefox에서 스크롤 바 숨기기
              msOverflowStyle: "none", // IE 및 Edge에서 스크롤 바 숨기기
              "&::WebkitScrollbar": { display: "none" },
            }}
          >
            {currentData.map((item, index) => (
              <li
                key={index}
                style={{ paddingBottom: "15px", cursor: "pointer" }}
                onClick={() => handleAroundClick(item.id)}
              >
                <div className={styles.selectedItemDetails} onClick={() => {}}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {getContractState(item)}
                      {item.new_house_nm || item.house_nm}
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "2px",
                      fontSize: "14px",
                      gap: "2px",
                    }}
                  >
                    <span>{getUnitPrice(item)}</span>
                    <span>
                      {`${getSupplyType(item)} | 분양 ${
                        item.tot_suply_hshldco
                      } 세대 | `}
                      {item.lcl_cmp_rate < 0 || item.lcl_cmp_rate === null
                        ? "미분양"
                        : `경쟁률 ${item.lcl_cmp_rate}`}
                    </span>
                    <span>{item.new_hssply_adres}</span>
                  </div>
                </div>
                {item.presale_info.length > 0 && (
                  <CustomCarouselSmall subList={item.presale_info} />
                )}
              </li>
            ))}
          </ul>
        </>
      );
    }
  };

  return (
    <>
      <div className={styles.detailWindow}>
        <div className={styles.content}>
          {/* <HorizontalLine size={1} color={"#D9D9D9"} /> */}
          {renderContent()}
        </div>
        {/* <SellRealEstateButton /> */}
      </div>
    </>
  );
};
export default AroundInfo;
