import React, { useState, useEffect, useRef, useCallback } from "react";
import { SlRefresh } from "react-icons/sl";
import styles from "./map.module.css"; // CSS 모듈 가져오기
import DetailMapInfo from "../detail_mapinfo/detail_mapinfo";
import { useNavigate } from "react-router-dom";
import { squareMeterToPyeong } from "../../utils/calculate";
import { simplifyAddress } from "../../utils/area_updator";

const Map = ({
  resultData,
  handleCurrentZoom,
  zoomLevel,
  searchType,
  mapService,
  showCategoryOptions,
  setShowSellPanel,
}) => {
  const mapRef = useRef(null); // 맵 DOM을 참조하는 Ref
  const [map, setMap] = useState(null); // 맵 인스턴스 상태

  const [overlay, setOverlay] = useState([]); // 버블 오버레이 상태
  const [changedMap, setChangedMap] = useState(false); // 맵 변경 여부 상태
  const [isMapLoaded, setIsMapLoaded] = useState(false); // 맵 로드 여부 상태

  const [regionPolygon, setRegionPolygon] = useState(null); // 지역 폴리곤 상태
  const [objectPolygons, setObjectPolygons] = useState([]); // 기타 나머지 폴리곤 상태

  const [isOpenDetail, setIsOpenDetail] = useState(true);

  const [windowHeight, setWindowHeight] = useState(35); // 창의 높이를 백분율로 관리 (50% 기본값)

  const [totalMapData, setTotalMapData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [otherOverlay, setOtherOverlay] = useState([]);
  const [otherObjectPolygons, setOtherObjectPolygons] = useState([]); // 기타 나머지 폴리곤 상태

  let expandedOverlay = null;

  const [selectedItemId, setSelectedItemId] = useState(null);
  const listRef = useRef(null);
  const [currentData, setCurrentData] = useState(); // 처음 5개의 아이템만 렌더링

  const navigation = useNavigate();

  useEffect(() => {
    const initializeMap = (latitude, longitude) => {
      // 맵 초기화 함수
      if (window.naver && mapRef.current) {
        const mapInstance = new window.naver.maps.Map(mapRef.current, {
          zoom: 17, // 초기 줌 레벨
          minZoom: 8, // 최소 줌 레벨
          maxZoom: 18, // 최대 줌 레벨
          center: new window.naver.maps.LatLng(latitude, longitude), // 초기 맵 중심 좌표
        });
        setMap(mapInstance); // 맵 인스턴스 상태 업데이트
      }
    };

    const getUserLocation = () => {
      // 사용자의 현재 위치를 가져오는 함수
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords; // 위치 좌표
            initializeMap(latitude, longitude); // 맵 초기화
          },
          (error) => {
            initializeMap(37.5665, 126.978); // 위치 가져오기 실패 시 서울 기본 좌표
          }
        );
      } else {
        initializeMap(37.5665, 126.978); // geolocation 지원하지 않을 경우 서울 기본 좌표
      }
    };

    getUserLocation(); // 사용자 위치 가져오기
  }, []);

  const drawChangedZoom = (resultData, zoomLevel) => {
    if (!map) return;
    clearObjectPolygons();
    clearBubbleContent();

    if (resultData?.length > 0) {
      resultData.forEach((data) => {
        if (zoomLevel === "lvl0") {
          // console.log(data);
          data.polygon && drawObjectPolygons(data.polygon);
          drawBubbleContent(data);
        } else {
          drawZoomBubbleContent(data, zoomLevel);
        }
      });
    }
  };

  useEffect(() => {
    if (!map) return;
    clearPolygons();
    clearBubbleContent();
    let isFirst = true;
    if (resultData) {
      if (searchType === "term") {
        if (resultData.aptInfo?.length > 0) {
          resultData.aptInfo.forEach((data) => {
            data.polygon && drawObjectPolygons(data.polygon); // 객체 폴리곤 그리기
            drawBubbleContent(data);
            if (isFirst) {
              // console.log(data.lat, data.lng);

              moveToArea(data.lat, data.lng);
              isFirst = false;
            }
          });
        } else {
        }
      } else if (searchType === "filter") {
        if (regionPolygon) {
          clearRegionPolygon();
        }

        //지역선택을 안하면 resultData.areaInfo가 없음
        if (resultData.areaInfo) {
          //줌 먼저 안하면 폴리곤, 마커가 안찍히는 버그있음
          map.setZoom(resultData.areaInfo.zoomSize);
          drawRegionPolygon(resultData.areaInfo?.polygon);
          if (resultData.aptInfo.length > 0) {
            resultData.aptInfo.forEach((data) => {
              data.polygon && drawObjectPolygons(data.polygon); // 객체 폴리곤 그리기
              drawBubbleContent(data);
              // console.log(data.lat);
              if (isFirst) {
                moveToArea(data.lat, data.lng);
                isFirst = false;
              }
            });
          } else {
            // drawRegionPolygon(resultData.areaInfo.polygon);
            moveToArea(resultData.areaInfo.lat, resultData.areaInfo.lng);
          }
        } else {
          //줌 먼저 안하면 폴리곤, 마커가 안찍히는 버그있음
          map.setZoom(16);

          if (resultData.aptInfo.length > 0) {
            resultData.aptInfo.forEach((data) => {
              data.polygon && drawObjectPolygons(data.polygon); // 객체 폴리곤 그리기
              drawBubbleContent(data);
              // console.log(data.lat);
              if (isFirst) {
                moveToArea(data.lat, data.lng);
                isFirst = false;
              }
            });
          } else {
            moveToArea(resultData.aptInfo[0].lat, resultData.aptInfo[0].lng);
          }
        }
      }
    }
  }, [map, resultData]);

  //현재 결과를 주소별로 그룹화해서 다시 결과값을 만듦
  const calculateAverages = (data, status) => {
    const groupBy = (arr, keyFunc) => {
      return arr.reduce((acc, item) => {
        const key = keyFunc(item);
        if (!acc[key]) acc[key] = [];
        acc[key].push(item);
        return acc;
      }, {});
    };

    // 각 레벨에 따라 그룹화 기준 설정
    const getAddressKey = (item) => {
      const addressParts = item.new_hssply_adres.split(" ");
      if (status === "lvl1") {
        return addressParts.slice(0, 3).join(" "); // 앞부터 3개
      } else if (status === "lvl2") {
        return addressParts.slice(0, 2).join(" "); // 앞부터 2개
      } else if (status === "lvl3") {
        return addressParts[0]; // 앞부터 1개
      }
    };

    // 데이터 그룹화
    const groupedData = groupBy(data, getAddressKey);
    // console.log(groupedData);

    // 그룹별로 max_supply_price와 house_supply_area 평균 계산
    const result = Object.keys(groupedData).map((key) => {
      const group = groupedData[key];
      // console.log(group);

      const totalMaxSupplyPrice = group.reduce(
        (sum, item) => sum + item.max_detail[0]?.max_supply_price,
        0
      );
      const totalHouseSupplyArea = group.reduce(
        (sum, item) => sum + parseFloat(item.max_detail[0]?.house_supply_area),
        0
      );

      return {
        address: key,
        avg_max_supply_price: totalMaxSupplyPrice / group.length,
        avg_house_supply_area: totalHouseSupplyArea / group.length,
      };
    });

    return result;
  };

  useEffect(() => {
    if (map) {
      // 줌 변경 이벤트 리스너 추가
      const zoomChangeListener = window.naver.maps.Event.addListener(
        map,
        "zoom_changed",
        () => {
          const newZoom = map.getZoom(); // 현재 줌 레벨 가져오기
          handleCurrentZoom(newZoom); // 상태 업데이트
        }
      );

      // 컴포넌트 언마운트 시 이벤트 리스너 제거
      return () => {
        window.naver.maps.Event.removeListener(zoomChangeListener);
      };
    }
  }, [map]);

  useEffect(() => {
    let zoomedData;
    // console.log(zoomLevel);

    const fetchDataWithCoordinates = async () => {
      if (
        zoomLevel === "lvl1" ||
        zoomLevel === "lvl2" ||
        zoomLevel === "lvl3"
      ) {
        clearObjectPolygons();
        zoomedData = calculateAverages(resultData.aptInfo, zoomLevel);
        try {
          const addresses = zoomedData.map((item) => item.address);
          const res = await mapService.getCoordinatesByAddresses(addresses);

          const result = zoomedData.map((address, index) => {
            const coord = res?.result[index];
            return {
              ...address,
              lat: coord ? coord.lat : null,
              lng: coord ? coord.lng : null,
            };
          });
          drawChangedZoom(result, zoomLevel);
        } catch (error) {
          console.error("Error fetching batch coordinates:", error);
        }
      } else {
        drawChangedZoom(resultData.aptInfo, zoomLevel);
      }
    };
    fetchDataWithCoordinates();
  }, [zoomLevel]);

  // 지역 폴리곤 그리기
  const drawRegionPolygon = (polygonCoords) => {
    clearRegionPolygon();
    // console.log("drawAreaPolygon called");
    // console.log(polygonCoords);

    const paths = polygonCoords.map((path) =>
      path[0].map(
        (coords) => new window.naver.maps.LatLng(coords[1], coords[0])
      )
    );

    const newPolygons = new window.naver.maps.Polygon({
      map,
      paths,
      strokeColor: "#79747E",
      strokeOpacity: 1.0,
      strokeWeight: 4,
      fillColor: "#D9D9D9",
      fillOpacity: 0.5,
    });

    setRegionPolygon(newPolygons); // 지역 폴리곤 업데이트
  };

  // 현 지도에서 검색 처리
  const handleCurrentMapSearch = async () => {
    const bounds = map.getBounds(); // 현재 맵의 경계 가져오기
    const params = new URLSearchParams({
      north: bounds.getNE().lat(), // 북쪽 경계
      east: bounds.getNE().lng(), // 동쪽 경계
      south: bounds.getSW().lat(), // 남쪽 경계
      west: bounds.getSW().lng(), // 서쪽 경계
    }).toString();

    const res = await mapService.getObjectsInBounds(params); // 현재 경계 내 객체 가져오기
    // console.log(resultData);

    const newItems = res.result.filter(
      (item) => !resultData.aptInfo.some((existing) => existing.id === item.id)
    );
    // console.log(newItems);

    setSearchData(newItems);

    clearOtherOverlay();
    newItems.forEach((item) => {
      item.polygon && drawObjectPolygons(item.polygon, true);
      drawBubbleContent(item, true); // Pass true to signify otherOverlay
    });

    setChangedMap(false);
  };

  useEffect(() => {
    if (resultData.aptInfo && searchData) {
      const newTotalData = [...searchData, ...resultData.aptInfo];
      // const setData = Object.values(
      //   data.reduce((acc, item) => {
      //     acc[item.id] = item; // id를 키로 사용하여 중복을 덮어씀
      //     return acc;
      //   }, {})
      // );
      setTotalMapData(newTotalData);
      setCurrentData(newTotalData.slice(0, 5));
      // console.log("totalData: ", data);
    }
  }, [resultData, searchData]);

  useEffect(() => {
    setCurrentData(totalMapData.slice(0, 5));
    if (listRef.current) {
      listRef.current.scrollTo({ top: 0 });
    }
  }, [totalMapData]);

  // BubbleContent 그리기
  const drawBubbleContent = (obj, isOtherOverlay = false) => {
    const getContractState = (obj) => {
      const today = new Date();
      const beginDate = new Date(obj.rcept_bgnde);
      const endDate = new Date(obj.rcept_endde);

      if (today < beginDate) {
        return "future-contract";
      } else if (today > endDate) {
        return "past-contract";
      } else {
        return "now-contract";
      }
    };

    const getAreaState = (obj) => {
      // console.log(obj);
      if (obj.length > 0) {
        const pyeong = squareMeterToPyeong(Number(obj[0].house_supply_area));
        const supplyAreaString = `${pyeong}평`;

        return supplyAreaString;
      } else {
        return `정보없음`;
      }
    };

    const getPriceState = (obj) => {
      if (obj.length > 0) {
        const Price = obj[0].max_supply_price;
        const supplyPriceString = `${(Price / 10000).toFixed(1)}억`;

        return supplyPriceString;
      } else {
        return `정보없음`;
      }
    };

    const getSupplyType = (obj) => {
      const supplyType = obj.house_secd_nm;
      if (supplyType === "APT") {
        return "아파트";
      }
    };

    const contractStateClass = getContractState(obj);
    const areaClass = getAreaState(obj.max_detail);
    const priceClass = getPriceState(obj.max_detail);
    const supplyType = getSupplyType(obj);
    // const bubbleContent = `
    //   <div class="${styles.bubbleContent} ${styles[contractStateClass]}" >
    //     <div class="${styles.bubbleHeader}">
    //       <div style="font-size:9px">
    //         ${areaClass}
    //       </div>
    //       <div>
    //         ${priceClass}
    //       </div>
    //     </div>
    //     <div class="${styles.bubbleBody}">
    //       <div style="font-weight:600">${obj.house_nm}</div>
    //       <div style="font-weight:600; font-size:15px">${priceClass}</div>
    //       <div>${supplyType} | 분양${obj.tot_suply_hshldco}세대</div>
    //     </div>
    //   </div>
    // `;
    const bubbleContent = `
    <div class="${styles.bubbleContent} ${styles[contractStateClass]}">
      <div class="${styles.flagPole}"></div>
      <div class="${styles.flagContent}">
        <div class="${styles.mainContent}">
          <div class="${styles.bubbleHeader}">
            <div>${areaClass}</div>
            <div>${priceClass}</div>
          </div>
          <div class="${styles.bubbleBody}">
            <div style="font-weight:600">${obj.house_nm}</div>
            <div style="font-weight:600;font-size: 14px">분양가 ${priceClass}</div>
            <div>${supplyType} | 분양${obj.tot_suply_hshldco}세대 | 매물 ${
      obj.presale_info.length
    }건</div>
          </div>
        </div>
        <div class="${styles.verticalText}">${
      contractStateClass === "future-contract"
        ? "분양전"
        : contractStateClass === "past-contract"
        ? "분양완료"
        : "분양중"
    }</div>
      </div>
    </div>
    `;

    const position = new window.naver.maps.LatLng(obj.lat, obj.lng);
    const newOverlay = new window.naver.maps.Marker({
      position,
      map,
      icon: {
        content: bubbleContent,
        anchor: new window.naver.maps.Point(10, 70), //마커 위치 조정
        className: styles.bubbleMarker,
      },
    });

    newOverlay.customData = obj;

    newOverlay.addListener("click", () => handleOverlayClick(newOverlay));

    newOverlay.setMap(map);

    isOtherOverlay
      ? setOtherOverlay((prevOverlays) => [...prevOverlays, newOverlay])
      : setOverlay((prevOverlays) => [...prevOverlays, newOverlay]);
  };

  const drawZoomBubbleContent = (obj, zoomLevel) => {
    const getAreaState = (obj) => {
      // console.log(obj);
      if (obj.avg_house_supply_area) {
        const pyeong = squareMeterToPyeong(Number(obj.avg_house_supply_area));
        const supplyAreaString = pyeong ? `${pyeong}평` : "정보없음";

        return supplyAreaString;
      } else {
        return "정보없음";
      }
    };

    const getPriceState = (obj) => {
      const Price = obj.avg_max_supply_price;
      const supplyPriceString = Price
        ? `${(Price / 10000).toFixed(1)}억`
        : "정보없음";

      return supplyPriceString;
    };
    const areaClass = getAreaState(obj);
    const priceClass = getPriceState(obj);
    // console.log(zoomLevel, obj.address);

    const bubbleContent = `
    <div class="${styles.bubbleContent} ${styles.zoom}">
      <div class="${styles.flagPole}"></div>
      <div class="${styles.flagContent}">
        <div class="${styles.mainContent}">
          <div class="${styles.bubbleHeader}">
            <div>${areaClass}</div>
            <div>${priceClass}</div>
          </div>
        </div>
        <div class="${styles.verticalText}">${
      zoomLevel === "lvl1"
        ? obj.address.split(" ")[2].replace(",", "") //특수문자 제거
        : zoomLevel === "lvl2"
        ? obj.address.split(" ")[1]
        : zoomLevel === "lvl3"
        ? simplifyAddress(obj.address) //서울특별시 -> 서울시 등 변경
        : ""
    }</div>
      </div>
    </div>
    `;

    const position = new window.naver.maps.LatLng(obj.lat, obj.lng);
    const newOverlay = new window.naver.maps.Marker({
      position,
      map,
      icon: {
        content: bubbleContent,
        anchor: new window.naver.maps.Point(10, 70), //마커 위치 조정
        className: styles.bubbleMarker,
      },
    });

    newOverlay.customData = obj;

    // newOverlay.addListener("click", () => handleZoomedOverlayClick(newOverlay));

    newOverlay.setMap(map);

    setOverlay((prevOverlays) => [...prevOverlays, newOverlay]);
  };

  const handleOverlayClick = useCallback(
    (overlay) => {
      const bubbleContentElement = overlay
        .getElement()
        .querySelector(`.${styles.bubbleContent}`);

      // 이미 확장된 말풍선이 있는 경우 처리
      if (expandedOverlay) {
        const prevBubbleContent = expandedOverlay
          .getElement()
          .querySelector(`.${styles.bubbleContent}`);

        // 동일한 말풍선을 클릭한 경우 (토글)
        if (expandedOverlay.customData.id === overlay.customData.id) {
          // prevBubbleContent.classList.remove(styles.expanded);
          // expandedOverlay = null; // 상태를 초기화하여 확장 상태 해제
          // setIsOpenDetail(false); // 상세창 닫기
          // return; // 동일한 말풍선이므로 여기서 종료
        } else {
          // 다른 말풍선을 클릭한 경우 이전 말풍선 닫기
          prevBubbleContent.classList.remove(styles.expanded);
        }
      }

      // 현재 클릭된 말풍선이 확장되지 않은 경우 확장하기
      bubbleContentElement?.classList.add(styles.expanded);
      expandedOverlay = overlay; // 확장된 말풍선을 저장

      // moveToArea(overlay.customData.lat, overlay.customData.lng);
      setIsOpenDetail(true);
      // handleBubbleClick(overlay.customData);
      handleItemClick(overlay.customData);
    },
    [map, mapService, resultData.aptInfo]
  );

  const handleZoomedOverlayClick = useCallback(
    (overlay) => {
      if (zoomLevel === "lvl1") {
        handleCurrentZoom(15);
        map.setZoom(15);
      } else if (zoomLevel === "lvl2") {
        handleCurrentZoom(14);
        map.setZoom(14);
      } else if (zoomLevel === "lvl3") {
        handleCurrentZoom(12);
        map.setZoom(12);
      }
      // console.log(zoomLevel, map.getZoom());
      // console.log(overlay.customData);

      moveToArea(overlay.customData.lat, overlay.customData.lng);
    },
    [map, zoomLevel]
  );

  // 객체 Polygon 그리기
  const drawObjectPolygons = (polygonCoords, isOtherOverlay = false) => {
    const paths = polygonCoords?.map((polygon) =>
      polygon[0].map(
        (coords) => new window.naver.maps.LatLng(coords[1], coords[0])
      )
    );

    const newPolygon = new window.naver.maps.Polygon({
      map,
      paths,
      strokeColor: "#007AFF",
      strokeOpacity: 1.0,
      strokeWeight: 2,
      fillColor: "#007AFF",
      fillOpacity: 0.6,
    });

    isOtherOverlay
      ? setOtherObjectPolygons((prevPolygons) => [...prevPolygons, newPolygon])
      : setObjectPolygons((prevPolygons) => [...prevPolygons, newPolygon]);
  };

  // 맵 위치 이동
  const moveToArea = (lat, lng) => {
    const position = new window.naver.maps.LatLng(lat, lng); // 이동할 위치

    const currentZoom = map.getZoom();
    // console.log(currentZoom);

    //TODO.줌에 따라서 영점이 안맞음(줌에 따라서 맞춰야함)
    const baseOffset = 0.0003; // 기본 오프셋 값
    const offset = baseOffset * Math.pow(2.5, currentZoom - 16);
    const newCenter = new window.naver.maps.LatLng(
      position.lat() - offset, // 오프셋을 추가하여 위로 이동
      position.lng()
    );
    // map.setCenter(newCenter);
    // 말풍선이 항상 중앙 위에 위치하도록 패닝
    map.panTo(newCenter, {
      duration: 300,
      easing: "easeOutCubic",
    });
  };

  useEffect(() => {
    if (map) {
      // 맵 이동 이벤트 리스너 추가
      window.naver.maps.Event.addListener(map, "idle", () => {
        setChangedMap(true); // 맵 이동 후 버튼 표시 상태 업데이트
        setIsMapLoaded(true); // 맵 로드 상태 업데이트
      });
    }
  }, [map]);

  const clearRegionPolygon = () => {
    if (regionPolygon) {
      regionPolygon.setMap(null); // 기존 지역 폴리곤 삭제
      setRegionPolygon(null); // 상태 초기화
    }
  };

  // 폴리곤 초기화
  const clearPolygons = () => {
    if (regionPolygon) {
      regionPolygon.setMap(null);
      setRegionPolygon(null);
    }
    clearObjectPolygons(); // 기타 폴리곤 초기화
  };

  // 기타 객체 폴리곤 초기화
  const clearObjectPolygons = () => {
    setObjectPolygons((prevPolygons) => {
      // 기존의 모든 폴리곤을 맵에서 제거
      prevPolygons.forEach((polygon) => polygon.setMap(null));
      return []; // 상태를 빈 배열로 초기화하여 다음 폴리곤을 그리기 전에 삭제
    });
    clearBubbleContent();
  };

  // bubbleContent 초기화 함수
  const clearBubbleContent = () => {
    setOverlay((prevOverlays) => {
      // 모든 오버레이를 삭제하고 상태를 빈 배열로 초기화
      prevOverlays.forEach((overlay) => overlay.setMap(null));
      return []; // 배열로 초기화하여 오류 방지
    });
  };

  const clearOtherOverlay = () => {
    setOtherObjectPolygons((prevPolygons) => {
      prevPolygons.forEach((polygon) => polygon.setMap(null));
      return [];
    });
    setOtherOverlay((prevOverlays) => {
      prevOverlays.forEach((overlay) => overlay.setMap(null)); // Remove from map
      return []; // Clear the array
    });
  };

  const handleBubbleClick = (item) => {
    moveToArea(item);
  };

  // console.log(resultData);

  useEffect(() => {
    showCategoryOptions ? setIsOpenDetail(false) : setIsOpenDetail(true);
  }, [showCategoryOptions, isOpenDetail]);

  const goDetailPage = (item) => {
    const encodeId = btoa(item.id);
    navigation(`/detail?id=${encodeId}`);
  };

  const handleItemClick = useCallback(
    (item) => {
      setSelectedItemId(item.id);
      // console.log(item);

      if (selectedItemId === item.id) {
        // 선택된 아이템을 다시 클릭하면 디테일 페이지로 이동
        const encodeId = btoa(item.id);
        navigation(`/detail?id=${encodeId}`);
      } else {
        // 아이템을 리스트 맨 위로 이동시키고 ID 저장
        setTotalMapData((prevTotalData) => {
          const updatedTotalData = [
            item,
            ...prevTotalData.filter((i) => i.id !== item.id),
          ];
          setCurrentData(updatedTotalData.slice(0, 5));
          return updatedTotalData;
        });

        handleBubbleClick(item); // 선택된 아이템 세부 정보 설정

        if (listRef.current) {
          listRef.current.scrollTo({ behavior: "smooth", top: 0 });
        }

        const overlayToExpand =
          overlay.find((o) => o.customData.id === item.id) ||
          otherOverlay.find((o) => o.customData.id === item.id);

        if (overlayToExpand) {
          overlayToExpand.trigger("click");
        }
      }
    },
    [
      totalMapData,
      selectedItemId,
      overlay,
      otherOverlay,
      handleBubbleClick,
      moveToArea,
    ]
  );

  // useEffect(() => {
  //   console.log(totalMapData);
  //   console.log(currentData);
  // }, [totalMapData]);

  return (
    <>
      <div ref={mapRef} className={styles.mapContainer} /> {/* 맵 컨테이너 */}
      {changedMap && windowHeight < 95 && (
        <div
          className={styles.refreshBtn}
          onClick={handleCurrentMapSearch}
          style={{ bottom: isOpenDetail ? `${windowHeight + 2}%` : "" }}
        >
          {/* 현 지도에서 검색 버튼 */}
          <SlRefresh style={{ width: "20px", height: "20px" }} />
          <span style={{ fontSize: 12, fontWeight: 600 }}>
            현 지도에서 검색
          </span>
        </div>
      )}
      {isOpenDetail && totalMapData.length > 0 && (
        <DetailMapInfo
          data={totalMapData}
          onClose={() => setIsOpenDetail(false)} // 닫기 기능
          windowHeight={windowHeight}
          handleWindowHeight={setWindowHeight}
          handleOpenDetail={setIsOpenDetail}
          handleItemClick={goDetailPage}
          listRef={listRef}
          currentData={currentData}
          setCurrentData={setCurrentData}
          setShowSellPanel={setShowSellPanel}
        />
      )}
    </>
  );
};

export default Map; // Map 컴포넌트 내보내기
